import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Seiber.tools</h1>
        <h5>Coming soon</h5>
      </header>
      <footer className="App-footer">
        <a href="mailto:seiber.tools@proton.me">seiber.tools@proton.me</a>
      </footer>
    </div>
  );
}

export default App;
